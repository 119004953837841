import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

import { Box, Divider, Typography } from '@mui/material'
import { ModelMenuSelectorField, MenuSelectorField, ModelAutocompleteField, DateTimeCompactComponent, DateTimeCompactField, TextField } from '@front/squirtle'
import FormatUtils from '@front/squirtle/utils/format'

const textStyle = {
  padding: 0,
  margin: 0,
  textAlign: 'right',
  color: 'text.input',
  fontWeight: 400,
  fontSize: '1vw'
}

const ContainerComponent = ({ label, children, hidden }) =>
  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
    <Box sx={{ flex: 1 }} hidden={hidden}>
      <Typography sx={{ color: 'text.label', fontSize: '1vw' }} >
        {`${label}:`}
      </Typography>

    </Box>
    <Box sx={{ flex: 1, justifyContent: 'flex-end' }} hidden={hidden}>
      {children}
    </Box>
  </Box>

const Col1 = ({ isB2B, modelMenuComponentProps, dispatchedAt, isReadOnly, contract, service, resetService, billingStatus }) =>
  <Box sx={{ mr: 1, flex: 1, display: 'flex', flexDirection: 'column' }}>
    <ContainerComponent label={I18n.t('billing.status.label', { count: 1 })}>
      <MenuSelectorField
        name={'billing_status'}
        options={_.map(billingStatus, value => ({ label: I18n.t(`billing.status.${value}`), value }))}
        loadOnMount
        {...modelMenuComponentProps}
      />
    </ContainerComponent>
    <Divider variant="middle" sx={{ my: 2 }} />
    <ContainerComponent label={I18n.t('contract.label', { count: 1 })} hidden={!isB2B}>
      <ModelAutocompleteField
        name={'user.contract'}
        model_name={'companycontract'}
        autocompleteKey={'client_id'}
        searchMode={'search'}
        searchQuery={'='}
        multiple={false}
        hiddenLabel
        loadOnFocus
        debounceTime={250}
        selectSingleOption
        afterChange={resetService}
        config={{
          mutator: companycontract => ({ label: `${_.get(companycontract, 'client_id')} - ${_.get(companycontract, 'name')}`, ...companycontract })
        }}
        renderInputProps={{
          inputProps: { style: { textAlign: 'right' } }
        }}
        required={!!contract}
        getOptionIcon={option => 'circle'}
        getOptionIconProps={option => ({
          base: 'material-icons',
          size: 10,
          sx: { color: FormatUtils.getStatusColor(FormatUtils.getStatus(option)) }
        })
        }
        getOptionProps={option => !!option && ({ disabled: FormatUtils.getStatus(option) === 'deleted' })}
      />
    </ContainerComponent>
    <ContainerComponent label={I18n.t('companyservice.label', { count: 1 })} hidden={!isB2B}>
      <ModelMenuSelectorField
        name={'user.service'}
        model_name={'companyservice'}
        searchMode={'search'}
        labelKeys={['client_id']}
        multiple={false}
        loadOnFocus
        selectSingleOption
        config={{
          populate: ['contract'],
          forced_filter: { contract },
          validate_filter: useCallback(() => !!contract, [contract]),
          watchers: [contract]
        }}
        required={!!service}
        {..._.omit(modelMenuComponentProps, ['valueDecoratorProps.sx.WebkitTextFillColor', 'valueDecoratorProps.sx.fontWeight'])}
        getOptionIcon={option => 'circle'}
        getOptionIconProps={option => ({
          base: 'material-icons',
          size: 10,
          sx: { color: FormatUtils.getStatusColor(FormatUtils.getStatus(option)) }
        })
        }
        getOptionProps={option => !!option && ({ disabled: FormatUtils.getStatus(option) === 'deleted' })}
      />
    </ContainerComponent>
    <ContainerComponent label={I18n.t('user.client_or_user')}>
      <ModelAutocompleteField
        name={'user.user_id'}
        model_name={'user'}
        autocompleteKey={'customerinfo.client_number'}
        labelKeys={['customerinfo.client_number']}
        searchMode={'search'}
        hiddenLabel
        searchQuery={'='}
        multiple={false}
        loadOnFocus
        debounceTime={250}
        selectSingleOption
        config={{
          populate: ['customerinfo']
        }}
        renderInputProps={{
          inputProps: { style: { textAlign: 'right' } }
        }}
      />
    </ContainerComponent>
    <Divider variant="middle" sx={{ my: 2 }} />
    <ContainerComponent label={I18n.t('date.dispatched')}>
      <DateTimeCompactComponent
        disabled
        value={dispatchedAt}
        inputFormat={'DD/MM/YYYY HH:mm'}
        renderInputProps={{ inputProps: { style: textStyle } }}
        disableOpenPicker
      />
    </ContainerComponent>
    <ContainerComponent label={I18n.t('date.at_src')}>
      <DateTimeCompactField
        disabled
        name={'assigned_transport.info.arrivedAt'}
        inputFormat={'DD/MM/YYYY HH:mm'}
        renderInputProps={{ inputProps: { style: textStyle } }}
        disableOpenPicker
      />
    </ContainerComponent>
    <ContainerComponent label={I18n.t('date.on_board')}>
      <DateTimeCompactField
        name={'assigned_transport.info.pickedAt'}
        inputFormat={'DD/MM/YYYY HH:mm'}
        renderInputProps={{ inputProps: { style: textStyle } }}
        disableOpenPicker={!!isReadOnly}
      />
    </ContainerComponent>
    <ContainerComponent label={I18n.t('date.at_dest')}>
      <DateTimeCompactField
        name={'assigned_transport.info.dropoffAt'}
        inputFormat={'DD/MM/YYYY HH:mm'}
        renderInputProps={{ inputProps: { style: textStyle } }}
        disableOpenPicker={!!isReadOnly}
        OpenPickerButtonProps={{
          style: {
            fontSize: 4
          },
          fontSize: 'small'
        }}
      />
    </ContainerComponent>
    <ContainerComponent label={I18n.t('date.at_dest')}>
      <DateTimeCompactField
        name={'returnAt'}
        disabled
        inputFormat={'DD/MM/YYYY HH:mm'}
        renderInputProps={{ inputProps: { style: textStyle } }}
        disableOpenPicker={!!isReadOnly}
        OpenPickerButtonProps={{
          style: {
            fontSize: 4
          },
          fontSize: 'small'
        }}
      />
    </ContainerComponent>
    <ContainerComponent label={I18n.t('date.at_dest')}>
      <TextField
        name={'assigned_transport.info.night'}
        inputFormat={'DD/MM/YYYY HH:mm'}
      />
    </ContainerComponent>
  </Box>

const Col2 = ({ returnAt, load_type, handleEffectiveDurationChange, order_durations, transport_durations, modelMenuComponentProps }) =>
  <Box sx={{ ml: 1, flex: 1, display: 'flex', flexDirection: 'column' }}>
    <ContainerComponent label={I18n.t('bank.payment_ref')}>
      <TextField InputProps={{ inputProps: { style: textStyle } }} name={'payment_ref'} disabled />
    </ContainerComponent>
    <Divider variant="middle" sx={{ my: 2 }} />
    <ContainerComponent label={I18n.t('bank.b2c.customer_ref')} >
      <TextField InputProps={{ inputProps: { style: textStyle } }} name={'customer_ref'} />
    </ContainerComponent>
    <ContainerComponent label={I18n.t('document.b2c.id')} >
      <TextField InputProps={{ inputProps: { style: textStyle } }} name={'order_doc'} />
    </ContainerComponent>
    <ContainerComponent label={I18n.t(load_type === 'user' ? 'transport.passenger' : 'contact.label.one')}  >
      <TextField InputProps={{ inputProps: { style: textStyle } }} name={'loads.src_contact'} alwaysInclude />
    </ContainerComponent>
    <Divider variant="middle" sx={{ my: 1 }} />
    <ContainerComponent label={I18n.t(`load.${load_type}.count`)} childGridProps={{ xs: 3, lg: 2, xl: 2 }}>
      <TextField InputLabelProps={{ style: { fontSize: '1vw' } }} InputProps={{ inputProps: { style: textStyle } }} name={'load_count'} type='number' />
    </ContainerComponent>
    <ContainerComponent label={I18n.t("bank.order_duration")} childGridProps={{ xs: 3, lg: 2, xl: 2 }}>
      <MenuSelectorField
        name='info.duration'
        options={order_durations}
        {..._.omit(modelMenuComponentProps, ['valueDecoratorProps.sx.WebkitTextFillColor', 'valueDecoratorProps.sx.fontWeight'])}
      />
    </ContainerComponent>
    <ContainerComponent label={I18n.t("bank.transport_duration")} childGridProps={{ xs: 3, lg: 2, xl: 2 }}>
      <MenuSelectorField
        name='assigned_transport.info.duration'
        options={transport_durations}
        {..._.omit(modelMenuComponentProps, ['valueDecoratorProps.sx.WebkitTextFillColor', 'valueDecoratorProps.sx.fontWeight'])}
      />
    </ContainerComponent>
    <ContainerComponent label={I18n.t('quotation.wait_calculated')} childGridProps={{ xs: 3, lg: 2, xl: 2 }}>
      <TextField
        name={'assigned_transport.info.effective_handling_duration'}
        type='number'
        InputLabelProps={{ style: { fontSize: '1vw' } }}
        _ardorment={{ sx: { fontSize: '1vw' } }}
        InputProps={{
          inputProps: { style: textStyle },
          endAdornment: I18n.t('minute.label.short')
        }}
        afterChange={handleEffectiveDurationChange}
      />
    </ContainerComponent>
    <ContainerComponent label={I18n.t('quotation.wait_paid')} childGridProps={{ xs: 3, lg: 2, xl: 2 }}>
      <TextField
        name={'assigned_transport.info.billed_handling_duration'}
        type='number'
        InputLabelProps={{ style: { fontSize: '1vw' } }}
        _ardorment={{ sx: { fontSize: '1vw' } }}
        InputProps={{
          inputProps: { style: textStyle },
          endAdornment: I18n.t('minute.label.short')
        }}
      />
    </ContainerComponent>
    <ContainerComponent label={I18n.t('codepromo.create')} >
      <TextField InputLabelProps={{ style: { fontSize: '1vw' } }} InputProps={{ inputProps: { style: textStyle } }} name={'codepromo'} disabled />
    </ContainerComponent>
  </Box >

const FirstRow = ({
  order_id,
  load_type,
  handleEffectiveDurationChange,
  resetService,
  isB2B,
  dispatchedAt,
  transport_at_source,
  isReadOnly,
  contract,
  service,
  order_durations,
  transport_durations,
  returnAt
}) => {

  const billingStatus = hooks.useBillingStatusList()
  const billing_color = hooks.useBillingStatusColor({ order_id })
  const modelMenuComponentProps = {
    valueDecoratorProps: {
      sx: {
        fontSize: '1vw',
        textAlign: 'right',
        fontWeight: 600,
        WebkitTextFillColor: billing_color
      }
    },
    selectProps: !!isReadOnly && {
      SelectDisplayProps: { sx: { p: '0 !important', m: '0 !important' } }
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Col1
        modelMenuComponentProps={modelMenuComponentProps}
        isB2B={isB2B}
        dispatchedAt={dispatchedAt}
        transport_at_source={transport_at_source}
        isReadOnly={isReadOnly}
        contract={contract}
        service={service}
        resetService={resetService}
        billingStatus={billingStatus}
      />
      <Col2
        modelMenuComponentProps={modelMenuComponentProps}
        handleEffectiveDurationChange={handleEffectiveDurationChange}
        load_type={load_type}
        order_durations={order_durations}
        transport_durations={transport_durations}
        returnAt={returnAt}
      />
    </Box>
  )
}


export default React.memo(FirstRow)
