import FormatUtils from '@front/squirtle/utils/format'
class Callbacks {
  static getEmptyFormHandler() {
    return function getEmptyForm() {
      return
    }
  }

  static recordToFormHandler() {
    return function recordToForm(record) {
      const fees = _.toNumber(_.get(record, 'assigned_transport.payment.fees') || 0)
      const base = _.toNumber(_.get(record, 'assigned_transport.payment.base') || 0)
      const prepaid = _.toNumber(_.get(record, 'assigned_transport.payment.prepaid') || 0)
      const handling = _.toNumber(_.get(record, 'assigned_transport.payment.handling') || 0)
      const discount = _.toNumber(_.get(record, 'assigned_transport.payment.discount') || 0)
      const duration_amount = _.toNumber(_.get(record, 'assigned_transport.payment.duration_amount') || 0)
      const package_fee = _.toNumber(_.get(record, 'assigned_transport.payment.driver_package_fee') || 0)
      const option_amount = _.toNumber(_.get(record, 'assigned_transport.payment.option_amount') || 0)
      const service_amount = _.toNumber(_.get(record, 'assigned_transport.payment.service_amount') || 0)

      const driver_fees = _.toNumber(_.get(record, 'job.payment.fees') || 0)
      const driver_base = _.toNumber(_.get(record, 'job.payment.base') || 0)
      const driver_prepaid = _.toNumber(_.get(record, 'job.payment.prepaid') || 0)
      const driver_handling = _.toNumber(_.get(record, 'job.payment.handling') || 0)
      const driver_duration_amount = _.toNumber(_.get(record, 'job.payment.duration_amount') || 0)
      const driver_package_fee = _.toNumber(_.get(record, 'job.payment.driver_package_fee') || 0)
      const driver_option_amount = _.toNumber(_.get(record, 'job.payment.option_amount') || 0)

      const billing_driver_comment = _.head(_.find(record?.events, ['name', 'driver_comment'])?.data)?.value

      return {
        returnAt: record?.returnAt,
        info: {
          duration: _.get(record, 'info.duration') || 0
        },
        user: {
          user_id: _.get(record, 'do.user_id'),
          client_number: _.get(record, 'do.customerinfo.client_number'),
          contract: _.get(record, 'service.contract'),
          service: _.get(record, 'service.companyservice_id'),
        },
        job: {
          dispatchedAt: _.get(record, 'job.dispatchedAt'),
          payment: {
            fees: driver_fees,
            base: driver_base,
            prepaid: driver_prepaid,
            handling: driver_handling,
            duration_amount: driver_duration_amount,
            driver_package_fee: driver_package_fee,
            option_amount: driver_option_amount
          }
        },
        loads: {
          src_contact: _.head(record?.loads)?.src_contact,
        },
        assigned_transport: {
          info: {
            night: _.get(record, 'assigned_transport.info.night'),
            arrivedAt: _.get(record, 'assigned_transport.info.arrivedAt'),
            pickedAt: _.get(record, 'assigned_transport.info.pickedAt'),
            dropoffAt: _.get(record, 'assigned_transport.info.dropoffAt'),
            effective_handling_duration: _.get(record, 'assigned_transport.info.effective_handling_duration'),
            billed_handling_duration: _.get(record, 'assigned_transport.info.billed_handling_duration'),
            duration: _.get(record, 'assigned_transport.info.duration') || 0,
          },
          payment: {
            fees,
            base,
            prepaid,
            handling,
            discount,
            duration_amount,
            driver_package_fee: package_fee,
            option_amount,
            service_amount,
          }
        },
        customer_ref: _.get(record, 'customer_ref'),
        order_doc: _.get(record, 'order_doc'),
        included_waiting_minute: _.get(record, 'specific_parameter.included_waiting_minute') || 0,
        codepromo: _.get(record, 'transaction.codepromo.codepromo.name'),
        load_count: _.get(record, 'assigned_transport.passenger_count') || _.get(record, 'assigned_transport.package_count'),
        payment_ref: _.get(record, 'payment_ref'),
        billing_status: _.get(record, 'billing_status'),
        billing_customer_comment: _.get(record, 'billing_customer_comment'),
        billing_group_comment: _.get(record, 'billing_group_comment'),
        billing_driver_comment
      }
    }
  }
  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        hasJobPayment: !!_.get(record, 'job.payment'),
        transport_id: _.get(record, 'assigned_transport.transport_id'),
        load_type: _.get(record, 'commercial_package.load_type'),
        isB2B: !!_.get(record, 'service'),
        ridemode_id: _.get(record, 'mode')
      }
    }
  }
  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, { load_type, transport_id, ...state }) {
      const toNumber = value => !!value ? _.toNumber(value) : undefined
      return _.merge({},
        !!transport_id ? {
          assigned_transport: {
            passenger_count: load_type === 'user' ? _.get(values, 'load_count') : undefined,
            package_count: load_type === 'package' ? _.get(values, 'load_count') : undefined,
            info: {
              startedAt: _.get(values, 'assigned_transport.info.startedAt') ? FormatUtils.formatDateFrontToBack(_.get(values, 'assigned_transport.info.startedAt')) : undefined,
              arrivedAt: _.get(values, 'assigned_transport.info.arrivedAt') ? FormatUtils.formatDateFrontToBack(_.get(values, 'assigned_transport.info.arrivedAt')) : undefined,
              effective_handling_duration: _.get(values, 'assigned_transport.info.effective_handling_duration'),
              billed_handling_duration: _.get(values, 'assigned_transport.info.billed_handling_duration'),
              duration: _.get(values, 'assigned_transport.info.duration'),
            },
            payment: {
              fees: toNumber(_.get(values, 'assigned_transport.payment.fees')),
              base: toNumber(_.get(values, 'assigned_transport.payment.base')),
              prepaid: toNumber(_.get(values, 'assigned_transport.payment.prepaid')),
              handling: toNumber(_.get(values, 'assigned_transport.payment.handling')),
              discount: toNumber(_.get(values, 'assigned_transport.payment.discount')),
              duration_amount: toNumber(_.get(values, 'assigned_transport.payment.duration_amount')),
              driver_package_fee: toNumber(_.get(values, 'assigned_transport.payment.driver_package_fee')),
              option_amount: toNumber(_.get(values, 'assigned_transport.payment.option_amount')),
              service_amount: toNumber(_.get(values, 'assigned_transport.payment.service_amount')),
            }
          },
        } : {}, {
        job: {
          payment: {
            fees: toNumber(_.get(values, 'job.payment.fees')),
            base: toNumber(_.get(values, 'job.payment.base')),
            prepaid: toNumber(_.get(values, 'job.payment.prepaid')),
            handling: toNumber(_.get(values, 'job.payment.handling')),
            duration_amount: toNumber(_.get(values, 'job.payment.duration_amount')),
            driver_package_fee: toNumber(_.get(values, 'job.payment.driver_package_fee')),
            option_amount: toNumber(_.get(values, 'job.payment.option_amount')),
          }
        },
        billing_status: _.get(values, 'billing_status'),
        customer_ref: _.get(values, 'customer_ref'),
        payment_ref: _.get(values, 'payment_ref'),
        order_doc: _.get(values, 'order_doc'),
        info: {
          duration: _.get(values, 'info.duration')
        },
        service: _.get(values, 'user.service'),
        do: _.get(values, 'user.user_id'),
        loads: {
          src_contact: _.get(values, 'loads.src_contact'),
        },
        billing_customer_comment: _.get(values, 'billing_customer_comment'),
        billing_group_comment: _.get(values, 'billing_group_comment'),
      })
    }
  }
  static formToOptionsHandler() {
    return function formToOptions(values, extra, meta, state) {
      return ({
        load_type: _.get(state, 'load_type'),
        load_count: _.get(values, 'load_count'),
      })
    }
  }

  static onSubmitSuccessHandler() {
    return function onSubmitSuccess(result, values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 166 ~ Callbacks ~ onSubmitSuccess ~ result", result)
    }
  }
  static onSubmitFailedHandler() {
    return function onSubmitFailed(err, values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 171 ~ Callbacks ~ onSubmitFailed ~ err", err)
    }
  }

}

export default Callbacks
